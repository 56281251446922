










































































































































































































import { Component, Vue } from "vue-property-decorator";
import Refresh from "@/components/defaultLayout/util/Refresh.vue";
import { FilterType, GetHistoriesInterface, Obj } from "@/types/objectType";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import { HistoryListResponse } from "@/generated/adminapi";
import { DataTableHeader } from "vuetify";
import ExcelDataUpload from "@/components/defaultLayout/report/ExcelDataUpload.vue";
import { DataOptions } from "vuetify";
import Pagination from "@/components/defaultLayout/util/Pagination.vue";
import DialogEditHistoryUserInfo from "@/components/defaultLayout/report/DialogEditHistoryUserInfo.vue";
import ApiHelperClass from "@/plugins/haii/api-helper";
import ExcelDownloadBtn from "@/components/defaultLayout/ReportList/ExcelDownloadBtn.vue";
import axios from "axios";

export interface ReportTableDataInterFace {
  historyUUID: string;
  userKey: string;
  name: string;
  gender: string;
  testDate: string;
  birthDT: string;
  phone: string;
  institutionName: string;
  couponCode: string;
  groupName: string;
  isSuspectedDementia?: boolean;
  reportUrl?: string;
  completedFlag: boolean;
}

@Component({
  components: {
    ExcelDownloadBtn,
    Refresh,
    TextField,
    DatePicker,
    ExcelDataUpload,
    Pagination,
    DialogEditHistoryUserInfo,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class ReportList extends Vue {
  title = this.$_haiiName();
  headers: DataTableHeader[] = [
    {
      text: "#",
      align: "center",
      value: "index",
      sortable: false,
    },
    {
      text: this.$_haiiText("userKey"),
      align: "center",
      value: "userKey",
      sortable: false,
    },
    {
      text: this.$_haiiText("userName"),
      value: "name",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("gender"),
      value: "gender",
      sortable: false,
      align: "center",
      width: "100px",
    },
    {
      text: this.$_haiiText("testDate"),
      value: "testDate",
      align: "center",
    },
    {
      text: this.$_haiiText("birthDT"),
      value: "birthDT",
      align: "center",
      sortable: false,
    },
    {
      text: this.$_haiiText("phone"),
      value: "phone",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("institutionName"),
      value: "institutionName",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("couponCode"),
      value: "couponCode",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("gameGroupName"),
      value: "groupName",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("completedFlag"),
      value: "completedFlag",
      sortable: false,
      align: "center",
    },

    {
      text: this.$_haiiText("result"),
      value: "completedFlag",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("downloadReport"),
      value: "completedFlag",
      sortable: false,
      align: "center",
    },

    {
      text: this.$_haiiText("editInfo"),
      align: "center",
      value: "actions",
      sortable: false,
    },
  ];

  readonly DEFAULT_FILTER: FilterType = {
    userKey: "",
    name: "",
    date: ["", ""],
    serverUrl: "",
    phone: "",
    code: "",
    companyName: "",
  };

  readonly DEFAULT_DATA_OPTIONS: Required<DataOptions> = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["date"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  historyListResponse = {} as HistoryListResponse;

  itemsPerPageOptions = [10, 30, 50, 100, 300, 500, 1000, 1500, 2000];
  dataOptions = {} as DataOptions;
  filter = {} as FilterType;
  totalNum = 0;
  pageCount = 0;
  dialog = false;
  editData = {} as ReportTableDataInterFace;
  companyList = [];

  get tableStyle(): string {
    const height = (window.innerHeight - this.$vuetify.application.top) * 0.5;

    return `min-width: 1400px; max-height : ${height}px`;
  }

  get tableData(): Array<ReportTableDataInterFace> {
    if (this.historyListResponse.total === undefined) {
      let temp = [];
      for (let i = 0; i < 10; i++) {
        temp.push({
          historyUUID: "",
          userKey: "",
          name: "",
          gender: "",
          testDate: "",
          birthDT: "",
          phone: "",
          institutionName: "",
          couponCode: "",
          groupName: "",
          completedFlag: "",
        });
      }
      return temp;
    }
    this.totalNum = this.historyListResponse.total;
    // histories가 없으면 에러 처리
    if (
      !this.historyListResponse.histories ||
      !this.historyListResponse.histories[0]
    ) {
      this.$_errorMessage(this.$_haiiText("noData"));
      return [];
    }
    return this.historyListResponse.histories?.map((history) => {
      return {
        historyUUID: history.UUID,
        userKey: history.user?.userKey,
        name: history.user?.displayName,
        gender:
          history.user.gender === "M"
            ? this.$_haiiText("male")
            : this.$_haiiText("female"),
        testDate: this.$_haii.datetime.toYYMMDD(history.CTS),
        birthDT: this.$_haii.datetime.toYYMMDD(history.user?.birthDT),
        phone: history.user?.phone.replace(
          /^(\d{2,3})(\d{3,4})(\d{4})$/,
          `$1-$2-$3`
        ),
        institutionName: history.user.coupon.admin.company.companyName,
        couponCode: history.user.coupon.code,
        groupName: history.user.coupon?.gameGroup?.displayName
          ? history.user.coupon.gameGroup.displayName
          : "N/A",
        completedFlag: history.completedFlag,
        isSuspectedDementia: history.user.isSuspectedDementia,
        reportUrl: history.user.reportUrl,
      };
    });
  }

  get phoneMaxLength(): number {
    const regExp = /([0-9]{10,11})/g;
    if (regExp.test(this.filter.phone)) {
      return 11;
    }
    return 13;
  }

  get queryDecoded(): GetHistoriesInterface | null {
    const query = this.$_haii.util.queryDecode(
      this.$route.query[this.$_haiiName()],
      this.DEFAULT_FILTER,
      this.DEFAULT_DATA_OPTIONS
    );

    if (query instanceof Error) {
      this.$_errorMessage(query.message);
      return null;
    }
    return query as Obj<GetHistoriesInterface>;
  }

  created(): void {
    this.$watch(`$route.query.${this.$_haiiName()}`, this.onRouteChanged, {
      deep: true,
      immediate: true,
    });
    this.getCompanyList();
    document.addEventListener("keydown", this.onEnterKeydown);
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code !== "Enter") {
      return;
    }
    this.onSearch();
  }

  onRouteChanged(): void {
    const query = this.queryDecoded;
    if (!query) {
      return;
    }
    this.filter = query.filter || {};

    this.dataOptions = query.dataOptions || this.DEFAULT_DATA_OPTIONS;
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);
      // 서버 데이터 호출
      const data = await this.$_apiHelper().v1AdminListHistory({
        filter: this.filter,
        dataOptions: this.dataOptions,
      });
      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage("fetchData::" + error);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: HistoryListResponse | string): void {
    if (typeof data == "string") {
      this.$_errorMessage(data);
      this.$_haii.analytics.errorEvent(data);
      return;
    }
    this.$_haii.analytics.readEvent(
      this.$_haiiName() + "_handleFetchData",
      null,
      ApiHelperClass.query({
        filter: this.filter,
        dataOptions: this.dataOptions,
      })
    );
    console.log(data);
    this.historyListResponse = data;
  }

  validatePhone(): boolean {
    const regExp =
      /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|01([0|1|6|7|8|9])([0-9]{7,8})/;
    if (this.filter.phone && !regExp.test(this.filter.phone)) {
      this.$_errorMessage(this.$_haiiText("phoneFormatError"));
      return false;
    }
    return true;
  }

  async onSearch(): Promise<void> {
    try {
      if (!this.validatePhone()) return;
      this.dataOptions.page = 1;
      await this.routeChange();
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  // 브라우저에서 url 복사 시에도 검색 결과 화면이 유지하기위해 설정
  async routeChange(ev?: Event | string): Promise<void> {
    try {
      //검색 옵션 base64 형태로 변환
      const query = this.$_haii.util.queryEncode(this.filter, this.dataOptions);

      // 기존 query 와 다르다면 $route.push 실행
      if (this.$route.query[this.$_haiiName()] !== query) {
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            [this.$_haiiName()]: query,
          },
        });
      }
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  async onSearchReset(): Promise<void> {
    try {
      // 필터값이 없으면 undefined
      this.filter = {};
      if (this.$route.query[this.$_haiiName()]) {
        await this.$router.push({
          path: this.$route.path,
        });
      }
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  async moveReport(
    ReportTableDataInterFace: ReportTableDataInterFace
  ): Promise<void> {
    try {
      await this.$router.push(
        `reports/${ReportTableDataInterFace.historyUUID}`
      );
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  async onPaginationUpdate(value: Partial<DataOptions>): Promise<void> {
    try {
      Object.assign(this.dataOptions, value);
      await this.routeChange();
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  editItem(value: ReportTableDataInterFace): void {
    this.editData = { ...value, gender: value.gender === "남성" ? "M" : "F" };
    this.dialog = true;
  }

  async getCompanyList(): Promise<void> {
    const data = await this.$_apiHelper().v1AdminListCompaniesName();

    if (typeof data === "string") {
      return this.$_errorMessage(data);
    }

    data.companies?.forEach((value) => {
      this.companyList.push(value.companyName);
    });
  }

  async onClickDownload(reportUrl: string): Promise<void> {
    const res = await axios.get(
      process.env.VUE_APP_ADMIN_SERVER_URL + "/" + reportUrl,
      {
        responseType: "blob",
      }
    );
    const url = URL.createObjectURL(res.data);

    const a = document.createElement("a");
    a.href = url;
    a.download = "report.pdf";
    a.click();
  }
}
