









import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryResponse } from "@/generated/adminapi";

@Component
export default class ResultReport extends Vue {
  @Prop({ type: Object, default: null, required: true })
  readonly testerInfo!: HistoryResponse;

  src = "";

  async mounted(): Promise<void> {
    const url = this.testerInfo.user.reportUrl;
    console.log(url);
    if (!url) return;
    this.src = process.env.VUE_APP_ADMIN_SERVER_URL + "/" + url;
  }
}
