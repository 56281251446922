













































import { Component, Prop, Vue } from "vue-property-decorator";
import BasicInfo from "@/components/defaultLayout/report/BasicInfo.vue";
import GameDetailInfo from "@/components/defaultLayout/report/GameDetailInfo.vue";
import { HistoryLogResponse, HistoryResponse } from "@/generated/adminapi";
import CistResult from "@/components/defaultLayout/report/CistResult.vue";
import ResultReport from "@/components/defaultLayout/report/ResultReport.vue";

@Component({
  components: {
    ResultReport,
    CistResult,
    BasicInfo,
    GameDetailInfo,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class Report extends Vue {
  @Prop() readonly UUID!: string;

  title = this.$_haiiName();
  testerInfo = {} as HistoryResponse;
  historyLogs = [] as HistoryLogResponse[];

  tab = 0;

  get isKo(): boolean {
    return this.$store.getters.setting.locale == "ko";
  }

  created(): void {
    this.$watch("$route", this.onRouteChanged, { immediate: true });
  }

  onRouteChanged(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);
      const data = await this.$_apiHelper().v1AdminRetrieveHistory(this.UUID);
      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: HistoryResponse | string): void {
    if (typeof data == "string") {
      this.$_errorMessage(data);
      return;
    }

    this.testerInfo = data;

    if (!data.historyLog) {
      this.$_errorMessage(this.$_haiiText("noData"));
      setTimeout((): void => {
        this.$router.go(-1);
      }, 1000);
      return;
    }

    if (data.historyLog.total == 0) {
      // 게임 데이타가 없는 경우
      this.$_errorMessage(this.$_haiiText("noGameData"));
      return;
    }

    this.historyLogs = data.historyLog.historyLogs
      .filter((value) => value.game?.itype !== 8600)
      .sort((a, b) => {
        if (!a.game || !b.game) return 0;
        return a.game.nth - b.game.nth;
      })
      .map((value) => {
        if (!this.isKo) {
          switch (value.game.itype) {
            case 8000:
              value.game.displayName = "Numeric stroop";
              break;
            case 8100:
              value.game.displayName = "Numeric stroop";
              break;
            case 8200:
              value.game.displayName = "Arithemetic";
              break;
            case 8300:
              value.game.displayName = "Symbol Association";
              break;
            case 8400:
              value.game.displayName = "pursuit";
              break;
            case 8500:
              value.game.displayName = "Saccade";
              break;
            case 8510:
              value.game.displayName = "Anti-Saccade";
              break;
            case 8700:
              value.game.displayName = "Smooth Pursuit";
              break;
            case 8800:
              value.game.displayName = "SOPT";
              break;
            case 8900:
              value.game.displayName = "Picture Description";
              break;
            case 8910:
              value.game.displayName = "Picture Description(all)";
              break;
            case 9000:
              value.game.displayName = "Mixed test";
              break;
          }
        }
        return value;
      });
  }
}
